<template>
  <div class="after-loop" style="margin: 20px;">
    <el-row style="margin: -50px 0 8px;">
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <h4>Dashboard</h4>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" align="right">
        <el-button type="primary" size="mini" style="border-radius: 5px;" @click="viewFile()">Download Manual</el-button>
      </el-col>
    </el-row>
    <el-row>
      <h5>Welcome {{ user.currentUser.name }}</h5>
    </el-row>
    <el-row :gutter="20">
      <el-col v-loading="loadingAnalytics" v-if="recordsMember" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link :to="{ name:'MINUTES' }" class="after-loop-item card border-0 card-guides shadow-lg">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1>{{ meta.total_minutes }}</h1>
            <p class="w-75">Minutes</p>
            <span class="i-icon"><i class="fa fa-history"/></span>
          </div>
        </router-link>
      </el-col>
      <el-col v-loading="loadingAnalytics" v-if="recordsMember" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link :to="{ name:'RESOLUTIONS' }" class="after-loop-item card border-0 card-guides shadow-lg">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1>{{ meta.total_resolutions }}</h1>
            <p class="w-75">Resolutions</p>
            <span class="i-icon"><i class="fa fa-vote-yea"/></span>
          </div>
        </router-link>
      </el-col>
      <el-col v-loading="loadingAnalytics" v-if="recordsMember" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link :to="{ name:'ANNUALREPORTS' }" class="after-loop-item card border-0 card-guides shadow-lg">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1>{{ meta.total_annual_reports }}</h1>
            <p class="w-75">Annual Reports</p>
            <span class="i-icon"><i class="fa fa-book-open"/></span>
          </div>
        </router-link>
      </el-col>
      <el-col v-loading="loadingAnalytics" v-if="recordsMember" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link :to="{ name:'MEMORANDUM' }" class="after-loop-item card border-0 card-guides shadow-lg">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1>{{ meta.total_memorandum }}</h1>
            <p class="w-75">Memorandum</p>
            <span class="i-icon"><i class="fa fa-file-alt"/></span>
          </div>
        </router-link>
      </el-col>
      <el-col v-loading="loadingAnalytics" v-if="minutesASSecretary" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link :to="{ name:'MINUTESS' }" class="after-loop-item card border-0 card-guides shadow-lg">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1>{{ meta.total_minutes_as }}</h1>
            <p class="w-75">Minutes</p>
            <span class="i-icon"><i class="fa fa-history"/></span>
          </div>
        </router-link>
      </el-col>
      <el-col v-loading="loadingAnalytics" v-if="minutesADSSecretary" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link :to="{ name:'MINUTESS' }" class="after-loop-item card border-0 card-guides shadow-lg">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1>{{ meta.total_minutes_ads }}</h1>
            <p class="w-75">Minutes</p>
            <span class="i-icon"><i class="fa fa-history"/></span>
          </div>
        </router-link>
      </el-col>
      <el-col v-loading="loadingAnalytics" v-if="minutesPBSecretary" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link :to="{ name:'MINUTESS' }" class="after-loop-item card border-0 card-guides shadow-lg">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1>{{ meta.total_minutes_apb }}</h1>
            <p class="w-75">APB Minutes</p>
            <span class="i-icon"><i class="fa fa-file-alt"/></span>
          </div>
        </router-link>
      </el-col>
      <el-col v-loading="loadingAnalytics" v-if="minutesPBSecretary" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link :to="{ name:'MINUTESS' }" class="after-loop-item card border-0 card-guides shadow-lg">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1>{{ meta.total_minutes_napb }}</h1>
            <p class="w-75">NAPB Minutes</p>
            <span class="i-icon"><i class="fa fa-file-alt"/></span>
          </div>
        </router-link>
      </el-col>
      <el-col v-loading="loadingAnalytics" v-if="minutesBSSecretary" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link :to="{ name:'MINUTESS' }" class="after-loop-item card border-0 card-guides shadow-lg">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1>{{ meta.total_minutes_bor }}</h1>
            <p class="w-75">BOR Minutes</p>
            <span class="i-icon"><i class="fa fa-file-alt"/></span>
          </div>
        </router-link>
      </el-col>
      <el-col v-loading="loadingAnalytics" v-if="minutesBSSecretary" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link :to="{ name:'MINUTESS' }" class="after-loop-item card border-0 card-guides shadow-lg">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1>{{ meta.total_minutes_uac }}</h1>
            <p class="w-75">UAC Minutes</p>
            <span class="i-icon"><i class="fa fa-file-alt"/></span>
          </div>
        </router-link>
      </el-col>
      <el-col v-loading="loadingAnalytics" v-if="minutesBSSecretary" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link :to="{ name:'MINUTESS' }" class="after-loop-item card border-0 card-guides shadow-lg">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1>{{ meta.total_minutes_uadco }}</h1>
            <p class="w-75">UADCO Minutes</p>
            <span class="i-icon"><i class="fa fa-file-alt"/></span>
          </div>
        </router-link>
      </el-col>
      <el-col v-loading="loadingAnalytics" v-if="memoSecretary" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link :to="{ name:'MEMORANDUMS' }" class="after-loop-item card border-0 card-guides shadow-lg">
          <div v-if="unit_id === 58" class="card-body d-flex align-items-end flex-column text-right"><h1>{{ meta.total_op_memorandum }}</h1><p class="w-75">OP Memorandum</p><span class="i-icon"><i class="fa fa-file-alt"/></span></div>
          <div v-else-if="unit_id === 60" class="card-body d-flex align-items-end flex-column text-right"><h1>{{ meta.total_ovpi_memorandum }}</h1><p class="w-75">OVPI Memorandum</p><span class="i-icon"><i class="fa fa-file-alt"/></span></div>
          <div v-else-if="unit_id === 59" class="card-body d-flex align-items-end flex-column text-right"><h1>{{ meta.total_ovpaf_memorandum }}</h1><p class="w-75">OVPAF Memorandum</p><span class="i-icon"><i class="fa fa-file-alt"/></span></div>
          <div v-else-if="unit_id === 91" class="card-body d-flex align-items-end flex-column text-right"><h1>{{ meta.total_ovpprgea_memorandum }}</h1><p class="w-75">OVPPRGEA Memorandum</p><span class="i-icon"><i class="fa fa-file-alt"/></span></div>
          <div v-else-if="unit_id === 61" class="card-body d-flex align-items-end flex-column text-right"><h1>{{ meta.total_ovpre_memorandum }}</h1><p class="w-75">OVPRE Memorandum</p><span class="i-icon"><i class="fa fa-file-alt"/></span></div>
        </router-link>
      </el-col>
      <el-col v-loading="loadingAnalytics" v-if="memoSecretary && ovpaaMemo" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link :to="{ name:'MEMORANDUMS' }" class="after-loop-item card border-0 card-guides shadow-lg">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1>{{ meta.total_ovpaa_memorandum }}</h1>
            <p class="w-75">OVPAA Memorandum</p>
            <span class="i-icon"><i class="fa fa-file-alt"/></span>
          </div>
        </router-link>
      </el-col>
      <el-col v-loading="loadingAnalytics" v-if="memoSecretary" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link :to="{ name:'MEMORANDUMCIRCULARS' }" class="after-loop-item card border-0 card-guides shadow-lg">
          <div v-if="unit_id === 58" class="card-body d-flex align-items-end flex-column text-right"><h1>{{ meta.total_op_memorandum_circular }}</h1><p class="w-75">OP Memorandum Circular</p><span class="i-icon"><i class="fa fa-file-alt"/></span></div>
          <div v-else-if="unit_id === 60" class="card-body d-flex align-items-end flex-column text-right"><h1>{{ meta.total_ovpi_memorandum_circular }}</h1><p class="w-75">OVPI Memorandum Circular</p><span class="i-icon"><i class="fa fa-file-alt"/></span></div>
          <div v-else-if="unit_id === 59" class="card-body d-flex align-items-end flex-column text-right"><h1>{{ meta.total_ovpaf_memorandum_circular }}</h1><p class="w-75">OVPAF Memorandum Circular</p><span class="i-icon"><i class="fa fa-file-alt"/></span></div>
          <div v-else-if="unit_id === 91" class="card-body d-flex align-items-end flex-column text-right"><h1>{{ meta.total_ovpprgea_memorandum_circular }}</h1><p class="w-75">OVPPRGEA Memorandum Circular</p><span class="i-icon"><i class="fa fa-file-alt"/></span></div>
          <div v-else-if="unit_id === 61" class="card-body d-flex align-items-end flex-column text-right"><h1>{{ meta.total_ovpre_memorandum_circular }}</h1><p class="w-75">OVPRE Memorandum Circular</p><span class="i-icon"><i class="fa fa-file-alt"/></span></div>
        </router-link>
      </el-col>
      <el-col v-loading="loadingAnalytics" v-if="memoSecretary && ovpaaMemo" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link :to="{ name:'MEMORANDUMCIRCULARS' }" class="after-loop-item card border-0 card-guides shadow-lg">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1>{{ meta.total_ovpaa_memorandum_circular }}</h1>
            <p class="w-75">OVPAA Memorandum Circular</p>
            <span class="i-icon"><i class="fa fa-file-alt"/></span>
          </div>
        </router-link>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-top: 13px;">
      <el-col v-loading="loadingAnalytics" v-if="recordsMember" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link :to="{ name:'MEMORANDUMCIRCULAR' }" class="after-loop-item card border-0 card-guides shadow-lg">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1>{{ meta.total_memorandum_circular }} </h1>
            <p class="w-75">Memorandum Circular</p>
            <span class="i-icon"><i class="fa fa-file-alt"/></span>
          </div>
        </router-link>
      </el-col>
      <el-col v-loading="loadingAnalytics" v-if="recordsMember" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link :to="{ name:'IPCR' }" class="after-loop-item card border-0 card-guides shadow-lg">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1>{{ meta.total_ipcr }} </h1>
            <p class="w-75">IPCR</p>
            <span class="i-icon"><i class="fa fa-user-alt"/></span>
          </div>
        </router-link>
      </el-col>
      <el-col v-loading="loadingAnalytics" v-if="recordsMember" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link :to="{ name:'OPCR' }" class="after-loop-item card border-0 card-guides shadow-lg">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1>{{ meta.total_opcr }} </h1>
            <p class="w-75">OPCR</p>
            <span class="i-icon"><i class="fa fa-building"/></span>
          </div>
        </router-link>
      </el-col>
      <el-col v-loading="loadingAnalytics" v-if="recordsMember" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link :to="{ name:'ACADEMIC' }" class="after-loop-item card border-0 card-guides shadow-lg">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1>{{ meta.total_faculty_file }} </h1>
            <p class="w-75">Academic 201 Files</p>
            <span class="i-icon"><i class="fa fa-users"/></span>
          </div>
        </router-link>
      </el-col>
      <el-col v-loading="loadingAnalytics" v-if="resolutionsBSSecretary" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link :to="{ name:'RESOLUTIONSS' }" class="after-loop-item card border-0 card-guides shadow-lg">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1>{{ meta.total_resolutions_bor }} </h1>
            <p class="w-75">BOR Resolutions</p>
            <span class="i-icon"><i class="fa fa-file-alt"/></span>
          </div>
        </router-link>
      </el-col>
      <el-col v-loading="loadingAnalytics" v-if="resolutionsBSSecretary" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link :to="{ name:'RESOLUTIONSS' }" class="after-loop-item card border-0 card-guides shadow-lg">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1>{{ meta.total_resolutions_uac }} </h1>
            <p class="w-75">UAC Resolutions</p>
            <span class="i-icon"><i class="fa fa-file-alt"/></span>
          </div>
        </router-link>
      </el-col>
      <el-col v-loading="loadingAnalytics" v-if="resolutionsBSSecretary" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link :to="{ name:'RESOLUTIONSS' }" class="after-loop-item card border-0 card-guides shadow-lg">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1>{{ meta.total_resolutions_uadco }} </h1>
            <p class="w-75">UADCO Resolutions</p>
            <span class="i-icon"><i class="fa fa-file-alt"/></span>
          </div>
        </router-link>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-top: 13px;">
      <el-col v-loading="loadingAnalytics" v-if="recordsMember" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link :to="{ name:'ADMINISTRATIVE' }" class="after-loop-item card border-0 card-guides shadow-lg">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1>{{ meta.total_staff_file }} </h1>
            <p class="w-75">Administrative 201 Files</p>
            <span class="i-icon"><i class="fa fa-users"/></span>
          </div>
        </router-link>
      </el-col>
      <el-col v-loading="loadingAnalytics" v-if="recordsMember" :xs="24" :sm="12" :md="12" :lg="6">
        <router-link :to="{ name:'TCT' }" class="after-loop-item card border-0 card-guides shadow-lg">
          <div class="card-body d-flex align-items-end flex-column text-right">
            <h1>{{ meta.tct }} </h1>
            <p class="w-75">Transfer Certificate of Title</p>
            <span class="i-icon"><i class="fa fa-certificate"/></span>
          </div>
        </router-link>
      </el-col>
    </el-row>
    <el-dialog
      :visible.sync="view_attach_form"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="closeFile"
      width="60%"
      top="5vh">
      <div>
        <embed :key="view_file" :src="view_file" style="width: 100%; height: 700px;">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Analytics } from '@/api/records/records_analytics'

export default {
  name: 'Dashboard',
  data() {
    return {
      message: '',
      meta: {},
      isFaculty: false,
      loadingAnalytics: false,
      i: 0,
      recordsMember: false,
      minutesASSecretary: false,
      minutesADSSecretary: false,
      minutesPBSecretary: false,
      minutesBSSecretary: false,
      resolutionsBSSecretary: false,
      memoSecretary: false,
      unit_id: null,
      ovpaaMemo: false,
      base_url: this.$store.getters.base_url,
      view_file: null,
      view_attach_form: false
    }
  },
  computed: {
    ...mapGetters([
      'username',
      'firstname',
      'lastname',
      'user'
    ])
  },
  watch: {
    i: function() {
      return this.i
    }
  },
  created() {
    this.checkRole()
    this.getAnalytics()
  },
  methods: {
    checkRole() {
      this.user.currentUser.roles.forEach(role => {
        if (role.name === 'records') this.recordsMember = true
        if (role.name === 'assecretary') this.minutesASSecretary = true
        if (role.name === 'adssecretary') this.minutesADSSecretary = true
        if (role.name === 'pbsecretary') this.minutesPBSecretary = true
        if (role.name === 'bssecretary') this.minutesBSSecretary = true
        if (role.name === 'resosecretary') this.resolutionsBSSecretary = true
        if (role.name === 'memosecretary') this.memoSecretary = true
      })
      if (this.$store.getters.user.currentUser.personnel) {
        this.unit_id = this.$store.getters.user.currentUser.personnel.department.id
        if (this.$store.getters.user.currentUser.personnel.department.id === 60) this.ovpaaMemo = true
        if (this.$store.getters.user.currentUser.personnel.position) {
          if (this.$store.getters.user.currentUser.personnel.position.type === 'Faculty') {
            this.isFaculty = true
          } else {
            this.isFaculty = false
          }
        }
      }
    },
    getAnalytics() {
      this.loadingAnalytics = true
      Analytics({}, 'get').then(res => {
        this.meta = res.data
        this.loadingAnalytics = false
      }).catch(error => {
        console.log(error)
        this.loadingAnalytics = false
      })
    },
    // downloadManual() {
    //   window.open(this.base_url + '/records/records_manual/download/HRMIS_Records_Manual.pdf', '_blank')
    // },
    viewFile() {
      this.view_file = this.base_url + '/records/records_manual/download/HRMIS_Records_Manual.pdf'
      this.view_attach_form = true
    },
    closeFile() {
      this.view_file = null
      this.view_attach_form = false
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .el-dialog__headerbtn {
    display: none;
  }
  .dashboard {
    &-container {
      margin-right: 10px;
      margin-left: 10px;
    }
    &-text {
      font-size: 30px;
      line-height: 46px;
    }
  }
  @import '../../styles/z-theme.css';

  .i-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 4rem;
    left: 1rem;
    color: rgba(255,255,255,.2);
    transition: .15s all ease;
  }
  .after-loop .card {
    margin-bottom: 10px;
  }
  .dark-green-bg{
    background-color: #136d27 !important;
  }
  .dark-red-bg{
    background-color: #d9534f !important;
  }
  .apply-tooltip {
    left: 0 !important;
  }
</style>
